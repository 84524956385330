import { Button } from '@components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@components/ui/dialog';
import { Input } from '@components/ui/input-2024';
import {
  PRICE_ID_REVENUE,
  PRO_BUNDLE_PRICE_ID,
  PRO_PLUS_BUNDLE_PRICE_ID,
  SINGLE_PACK_PRICE_ID,
  SUBSCRIPTION_PLAN
} from '@/_data/constants';
import { getCheckoutSession } from '@/_data/stripe/getCheckoutSession';
import { useSession } from '@hooks/index';
import { cn } from '@lib/utils';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useTrackEvent } from '@analytics/trackEvent';
import { sendGTMEvent } from '@next/third-parties/google';
import { Alert, AlertDescription, AlertTitle } from '@components/ui/alert';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@components/ui/card';
import { Check, Loader2 } from 'lucide-react';
import Link from 'next/link';
import { api } from '@/trpc/react';
import { env } from '@acme/env';

// don't change the order of the features, we expect the 2 element to be the photo count
export const tiers = [
  {
    name: 'Starter Kit',
    id: 'tier-starter',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/fbf5f12f-3e3c-4fd5-ba73-e4a14083f800/optimized',
    price: '$' + PRICE_ID_REVENUE[env.NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID],
    pricePerPhoto: '$0.75 per photo',
    features: [
      'One person',
      '40 photos', // price per photo
      'One photo pack',
      'Delivered in 30 mins',
      'Additional packs just $' + PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]
    ],
    mostPopular: false
  },
  {
    name: 'Pro Bundle',
    id: 'tier-pro-bundle',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/96f5426d-18f5-4bac-a638-ca4b405fa900/optimized',
    price: '$' + PRICE_ID_REVENUE[PRO_BUNDLE_PRICE_ID],
    pricePerPhoto: '$0.33 per photo',
    features: [
      'One person',
      '120 photos',
      'Three photo packs',
      'Delivered in 30 mins',
      'Additional packs just $' + PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]
    ],
    mostPopular: true
  },
  {
    name: 'Pro+ Bundle',
    id: 'tier-pro-plus-bundle',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/f4eb55a6-8f28-47f7-dfc2-804bcb8fe200/optimized',
    price: '$' + PRICE_ID_REVENUE[PRO_PLUS_BUNDLE_PRICE_ID],
    pricePerPhoto: '$0.30 per photo',
    features: [
      'One person',
      '200 photos',
      'Five photo packs',
      'Delivered in 30 mins',
      'Additional packs just $' + PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]
    ]
  }
];

interface PricingProps {
  coupon?: string;
  discountPercent?: number;
  expiresAt?: number;
  className?: string;
}

export default function Pricing({ coupon, discountPercent = 20, expiresAt }: PricingProps) {
  const router = useRouter();
  const { trackEvent } = useTrackEvent();
  const { isLoggedIn, isLoading } = useSession();
  const subscriptionQuery = api.user.getSubscriptionStatus.useQuery(undefined, {
    enabled: isLoggedIn
  });

  const [isGettingSession, setIsGettingSession] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isGiftDialogOpen, setIsGiftDialogOpen] = React.useState(false);
  const [friendsEmail, setFriendsEmail] = React.useState('');
  const [tier, setTier] = React.useState<(typeof tiers)[0] | null>(null);

  const calculatePrice = (originalPrice: string) => {
    const priceNumber = parseFloat(originalPrice.replace('$', ''));
    const discountedPrice = priceNumber * (1 - discountPercent / 100);
    return {
      original: `$${priceNumber}`,
      discounted: `$${discountedPrice.toFixed(2)}`
    };
  };

  const tiersWithDiscount = tiers.map(tier => ({
    ...tier,
    prices: calculatePrice(tier.price)
  }));

  const handlePay = async (tier: (typeof tiers)[0]) => {
    if (isGift) {
      setTier(tier);
      setIsGiftDialogOpen(true);
      return;
    }

    if (!isLoggedIn) {
      const currentPathname = router.pathname;
      router.push({
        pathname: '/login',
        query: { redirect: '/pricing', source: currentPathname }
      });
    } else {
      setIsGettingSession(true);
      try {
        if (coupon || subscriptionQuery?.data?.plan === SUBSCRIPTION_PLAN.picstudio_drive) {
          trackEvent('initiateCheckout', {
            value: tier.id
          });
          sendGTMEvent({
            event: 'initiateCheckout',
            value: tier.id
          });
          const session = await getCheckoutSession(tier.id, undefined, coupon);
          router.push(session.url);
        } else {
          router.push('/drive?tier=' + tier.id);
        }
      } catch (error) {
        console.error('Error during checkout process:', error);
        setErrorMessage('An error occurred. Please try again.');
      } finally {
        setIsGettingSession(false);
      }
    }
  };

  const isGift = router.query.gift === 'true';

  const handleSubmitGift: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (!tier) return;
    setIsGettingSession(true);
    const session = await getCheckoutSession(tier.id, friendsEmail);
    setIsGettingSession(false);

    // route to session.url
    router.push(session.url);
  };

  const formatExpiryDate = (timestamp?: number) => {
    if (!timestamp) return null;
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  return (
    <div
      className="overflow-hidden mx-auto  px-4 md:px-10 xl:px-20"
      //  DON'T REMOVE THIS ID, WE USE IT TO SCROLL TO THIS SECTION (USING THE #pricing in the URL)
      id="pricing"
    >
      <div className="mx-auto px-6 pb-24 text-left md:text-center w-full flex flex-col relative">
        {coupon && (
          <Alert className="mb-8 max-w-2xl mx-auto relative">
            <div className="flex flex-col items-center">
              <AlertTitle className="text-lg font-bold">Exclusive {discountPercent}% Discount Applied!</AlertTitle>
              <AlertDescription className="text-sm">
                Your special discount has been automatically applied to all packages below.
                {expiresAt && (
                  <span className="block mt-2 font-medium">
                    ⏰ Hurry! This offer expires on {formatExpiryDate(expiresAt)}
                  </span>
                )}
              </AlertDescription>
            </div>
          </Alert>
        )}

        <div className="mx-auto w-full max-w-screen-xl">
          <div className="mb-6">
            <h2 className="text-base font-semibold leading-7 text-foreground">Pricing</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-foreground sm:text-5xl mb-10 w-full">
              {isGift ? 'Get a bundle for a friend' : 'Pro Headshots. No Photographer.'}
            </p>
          </div>

          <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-3 text-left ">
            {tiersWithDiscount.map(tier => (
              <Card
                key={tier.id}
                className={cn('relative mb-0 bg-card/50', tier.name === 'Pro Bundle' ? 'border-accent' : '')}
              >
                <CardHeader className="pb-4">
                  <Image
                    src={tier.image}
                    alt={tier.name}
                    width={512}
                    height={512}
                    className="mb-4 h-auto aspect-square w-1/4 rounded-xl"
                  />

                  <CardTitle
                    className={cn(
                      'text-lg font-semibold leading-7',
                      tier.name === 'Pro Bundle' ? 'text-accent' : 'text-muted-foreground'
                    )}
                  >
                    {tier.name}
                  </CardTitle>
                  <CardDescription className="text-4xl !mt-0">
                    <>
                      {coupon ? (
                        <>
                          <span className="font-bold tracking-tight text-foreground">{tier.prices.discounted}</span>
                          <span className="text-lg line-through text-gray-500">{tier.prices.original}</span>
                        </>
                      ) : (
                        <span className="font-bold tracking-tight text-foreground">{tier.prices.original}</span>
                      )}
                    </>
                  </CardDescription>
                  {tier.mostPopular ? (
                    <p className="rounded-full px-2.5 py-1 text-xs font-semibold leading-5 text-white bg-accent w-fit absolute top-3 right-3">
                      Most popular
                    </p>
                  ) : null}
                </CardHeader>

                <CardContent className="">
                  <div>
                    <ul role="list" className="text-base leading-6 text-foreground space-y-1">
                      {tier.features.map((feature, index) => (
                        <li
                          key={feature}
                          className={cn(
                            'flex items-center',
                            tier.name === 'Pro Bundle' ? 'font-semibold text-foreground' : 'text-muted-foreground'
                          )}
                        >
                          <Check
                            strokeWidth={2}
                            className={cn(
                              'w-4 h-4 mr-2',
                              tier.name === 'Pro Bundle'
                                ? 'text-green-600 dark:text-green-300'
                                : 'text-muted-foreground'
                            )}
                          />
                          {feature}
                          {index === 1 && (
                            <span className="ml-1 text-xs text-green-600 dark:text-green-300">
                              ({tier.pricePerPhoto})
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardContent>
                <CardFooter>
                  <Button
                    disabled={isGettingSession || isLoading || (isLoggedIn && subscriptionQuery?.isPending)}
                    onClick={() => handlePay(tier)}
                    aria-describedby={tier.id}
                    variant={tier.name === 'Pro Bundle' ? 'accent' : 'outline'}
                    size={'lg'}
                    data-product-id={tier.id}
                    data-product-price={coupon ? tier.prices.discounted : tier.prices.original}
                    data-product-photo-count={tier.name === 'Pro Bundle' ? '120' : '40'}
                    data-product-pack-count={tier.name === 'Pro Bundle' ? '3' : '1'}
                    data-product-name={tier.name}
                    className="w-full"
                  >
                    {isGettingSession ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <>
                        {isGift ? 'Gift' : 'Buy'}{' '}
                        {tier.name === 'Pro Bundle' ? '120' : tier.name === 'Pro+ Bundle' ? '200' : '40'} portraits
                      </>
                    )}
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>

          <div className="mt-8 mx-auto">
            <Card className="overflow-hidden !gap-0">
              <div className="flex flex-col md:flex-row h-full">
                <div className="md:order-2 md:w-1/2 bg-accent/10 flex items-center justify-center p-8">
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/85905693-0c61-4d6a-8518-4faefc29b800/optimized"
                    alt="Headshot"
                    className="aspect-auto w-64"
                    width={256}
                    height={304}
                  />
                </div>
                <div className="flex flex-1 flex-col justify-start items-start bg-red-500/0 p-6 md:order-1 text-left">
                  <div className="max-w-md gap-2 flex flex-col justify-center h-full">
                    <CardTitle>Looking for Team Pricing?</CardTitle>
                    <CardDescription className="mb-4">Check out our special plans for teams</CardDescription>
                    <Button variant="secondary" className="flex items-center gap-2 w-full lg:w-fit">
                      <Link href="/pricing/teams">View Team Plans →</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <Dialog
            open={isGiftDialogOpen}
            onOpenChange={isOpen => {
              setIsGiftDialogOpen(isOpen);
            }}
          >
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Gift a bundle to a friend!</DialogTitle>
                <DialogDescription>Enter their email and we will send them the goods.</DialogDescription>
                {errorMessage && (
                  <DialogDescription>
                    <div className="text-red-500 text-sm">{errorMessage}</div>
                  </DialogDescription>
                )}
              </DialogHeader>
              <form onSubmit={handleSubmitGift}>
                <Input
                  value={friendsEmail}
                  onChange={e => setFriendsEmail(e.target.value)}
                  id="friendsEmail"
                  label="Friends email"
                  type="email"
                />
                <DialogFooter>
                  <Button disabled={isGettingSession} type="submit" className="mt-4">
                    Continue {isGettingSession && <Loader2 className="animate-spin" />}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
